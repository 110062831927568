<template>
    <div class="pl-order-wrap">
        <div class="module-title pl-flex" style="margin-bottom:30px">
            <span>收货信息</span>
            <span class="pl-add-addr" @click="addAddrFun" style="color: #d9262c;">新增收货地址</span>
        </div>
        <div class="pl-addr-card-box pl-flex-start">
            <div :class="['pl-addr-box', activeIndex === index ? 'active' : '']" v-for="(item, index) in addressArr"
                :key="index" @click="selectAddr(index)">
                <!-- <div class="pl-company-name">{{userInfo.company_name}}</div> -->
                <div class="pl-person pl-flex-start color">
                    <p>{{ item.consignee }} (收)</p>
                    <p>{{ item.contact }}</p>
                </div>
                <div class="pl-addr color">
                    {{ item.province }}{{ item.city }}{{ item.area }}
                    {{ item.address }}
                </div>
                <div class="pl-addr-card-footer color">
                    <span class="eidtor" @click.stop="editorAddr(item.id)" style="color: #d9262c;">修改</span>
                    <!-- <el-tag type="info" class="pl-tag">默认收货地址</el-tag> -->
                    <span class="pl-tag" v-if="item.is_default">默认地址</span>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="module-title margin">商品明细</div>
        <el-table :data="tableData" border class="pl-table">
            <el-table-column label="商品名称" width="200" prop="goods_name" header-align="center"
                align="center"></el-table-column>
            <el-table-column label="型号" width="200" prop="production_model" header-align="center"
                align="center"></el-table-column>
            <el-table-column label="数量" prop="quantity" header-align="center" align="center">
            </el-table-column>
            <el-table-column label="折扣单价" prop="price" header-align="center" align="center">
              <template slot-scope="scope">
                ¥ {{scope.row.price}}
              </template>
            </el-table-column>
            <el-table-column width="150" label="折扣单价(含税)" prop="tax_price" header-align="center" align="center">
              <template slot-scope="scope">
                ¥ {{scope.row.tax_price}}
              </template>
            </el-table-column>
            <el-table-column label="发货天数" prop="ship_days" header-align="center" align="center"></el-table-column>
            <el-table-column label="合计" prop="total_price" header-align="center" align="center">
              <template slot-scope="scope">
                ¥ {{scope.row.total_price}}
              </template>
            </el-table-column>
        </el-table>
        <div class="module-title margin">配送方式</div>
        <el-radio class="pl-send" v-model="sendType" :label="1">一起发货：所有商品到齐后一起发货</el-radio>
        <br />
        <el-radio v-model="sendType" :label="2">分开发货：商品将会被按照最早的时间来发货，发货日为同一日期的商品会一起发送</el-radio>
        <div class="module-title margin">支付方式</div>
        <el-radio-group v-model="payType">
            <el-radio :label="1" class="pl-pay">微信</el-radio>
            <el-radio class="pl-pay" :label="2">支付宝</el-radio>
            <el-radio :label="3" class="pl-pay">银联</el-radio>
        </el-radio-group>
        <Blank v-if="payType == 3" />
        <div class="pl-order-info">
            <div>
                <div class="pl-row">实付金额：<span>￥{{ total_amount }}</span></div>
                <div class="pl-addr">寄送至： {{ addrInfo.province || '' }}{{ addrInfo.city || '' }}{{ addrInfo.area ||
                    '' }}{{ addrInfo.address || '' }}</div>
                <div>收件人：{{ addrInfo.consignee }}</div>
            </div>
        </div>
        <div class="pl-btn">
            <el-button type="primary" @click="submitOrder" :loading="isLoading">提交订单</el-button>
        </div>
        <el-dialog title="收货地址" :visible.sync="showAddrCard">
            <addrCard :editId="editId" @hideModal="updateAddrFun"></addrCard>
        </el-dialog>
        <PayQr :id="wxId" :wxurl="wxUrl" :show.sync="showWxPay" />
    </div>
</template>
<script>
import { ordersConfirm, addressList, submitOrders } from '@/api/shoping.js'
import { alipay, wxpay } from '@/api/pay.js'
import addrCard from '@/components/user/address/add.vue'
import { mapState } from 'vuex'
import Blank from './bank.vue'
import PayQr from './pay.vue'
export default {
    components: {
        addrCard,
        Blank,
        PayQr
    },
    data() {
        return {
            activeIndex: 0,
            payType: 1,
            sendType: 1,
            tableData: [],
            params: this.$route.query || {},
            addressArr: [],
            editId: 0,
            showAddrCard: false,
            isLoading: false,
            alipay,
            wxId: '',
            wxUrl: '',
            showWxPay: false,
            total_amount: "",
        }
    },
    computed: {
        ...mapState(['userInfo']),
        addrInfo() {
            return this.addressArr[this.activeIndex] || {}
        },
        totalPrice() {
            let total_price = 0
            this.tableData.forEach(item => {
                total_price += item.total_price * 10000
            })
            return (total_price / 10000).toFixed(2)
        },

    },
    created() {
        this.ordersConfirm()
        this.addressList()
    },
    methods: {
        // 提交订单
        submitOrder() {

            let param = Object.assign({}, this.params)
            if (!this.addrInfo.id) return this.$message.error('请先填写收货地址')
            this.isLoading = true
            param.address_id = this.addrInfo.id
            param.pay_type = this.payType
            param.delivery_method = this.sendType
            submitOrders(param).then(res => {
                this.isLoading = false
                if (this.payType == 1) {
                    wxpay({ order_id: res.id }).then(({ code_url }) => {
                        this.wxId = res.id
                        this.wxUrl = code_url
                        this.showWxPay = true

                    })
                } else if (this.payType == 2) {
                    this.alipay += res.id
                    window.open(this.alipay, '_blank')
                } else {
                    this.$router.push('/user/order')
                    this.$message.success('提交成功，我司收到汇款后会立即处理订单.')
                }


            }).catch(() => this.isLoading = false)
        },
        // 选择地址
        selectAddr(index) {
            this.activeIndex = index;
        },
        // 更新地址
        updateAddrFun(res) {
            if (res.type) {
                // 确定
                this.addressList()
            }
            this.showAddrCard = false
        },
        // 新增地址
        addAddrFun() {
            this.editId = 0
            this.showAddrCard = true
        },
        // 订单信息
        ordersConfirm() {
            if (!Object.keys(this.params).length) return
            ordersConfirm(this.params).then((res) => {
                let { goods, total_amount } = res
                this.total_amount = total_amount;
                this.tableData = goods || []
            })
        },
        // 收货地址
        addressList() {
            addressList({ page: 1, per_page: 20 }).then(({ data }) => {
                let addrs = data || []
                let index = 0
                for (let i = 0, len = addrs.length; i < len; i++) {
                    if (addrs[i].is_default) {
                        index = i
                        break
                    }
                }
                this.activeIndex = index
                this.addressArr = addrs

            })
        },
        editorAddr(id) {
            this.editId = id;
            this.showAddrCard = true
        }
    }
}
</script>
<style lang="scss" scoped>
.pl-order-wrap {
    .margin {
        margin: 50px 0 30px;
    }

    .pl-add-addr {
        font-size: 14px;
        color: #3385FF;
    }

    .pl-addr-box {
        cursor: pointer;
        width: 300px;
        height: 150px;
        background: url('../../../assets/images/user/addr2.png') no-repeat;
        background-size: 100%;
        padding: 15px;
        box-sizing: border-box;
        margin-right: 5px;
        opacity: .7;

        .pl-company-name {
            font-weight: 600;
            font-size: 18px;
            color: gray;
        }

        .pl-person {
            margin: 10px 0;
        }
    }

    .pl-addr-card-box {
        flex-wrap: wrap;

        .pl-addr-card-footer {
            text-align: right;
            margin-top: 15px;

            .eidtor {
                display: none;
            }

            .pl-tag {
                // border: 1px solid #e1e1e1;
                padding: 5px;
            }
        }

        .active {
            background: url('../../../assets/images/user/addr.png') no-repeat;
            background-size: 100%;

            .pl-addr-card-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                // margin-top: 5px;
                .eidtor {
                    display: inline-block;
                    color: #3385FF;
                }

            }

            .pl-company-name {
                color: #000;
            }

            .color {
                color: #000;
            }
        }
    }

    .pl-send {
        margin-bottom: 15px;
    }

    .pl-pay {
        margin: 15px;
    }

    .pl-order-info {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: flex-end;

        .pl-row {
            font-weight: 600;

            span {
                color: #D9262c;
                font-size: 18px;
                font-weight: 600;
            }

        }

        .pl-addr {
            margin: 15px 0;
            line-height: 1.2;
        }

    }

    .pl-btn {
        display: flex;
        justify-content: flex-end;
        margin: 15px
    }

    .pl-addr {
        line-height: 1.2;
    }
}

::v-deep {
    .el-radio__input.is-checked+.el-radio__label {
        color: #D9262c;
    }

    .el-radio__input.is-checked .el-radio__inner {
        background: #D9262c;
        border: #D9262c;
    }

    .el-button--primary {
        color: #fff;
        background: #D9262c;
        border-color: #D9262c;
    }



}
</style>
